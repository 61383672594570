import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import EnterEmailForm from './EnterEmailForm/EnterEmailForm'
import { LoginErrorMessage } from 'Login/components'
import { marketingLoginG2,marketingLoginTr } from 'common/images/remote/urls.json'

import './styles.css.scss'

const { object, func, string } = PropTypes

class EnterEmail extends Component {
  componentDidUpdate(prevProps, prevState) {
    // in case there is an error and captcha is visible, reset the captcha component to reuse
    if (this.props.error !== '' && prevProps.error !== this.props.error && this.props.renderCaptcha) {
      this.captcha.reset()
    }
  }

  render() {
    return (
      <div className='enter-email'>
        { this.props.isSending
          ? <div className='spinner'/>
          : <EnterEmailForm
              {...this.props.form}
              onChange={this.props.onFormChange}
              onSubmit={this.props.onFormSubmit}>
            { this.props.renderCaptcha &&
              <div className='captcha-container'>
                <ReCAPTCHA
                  ref={r => this.captcha = r}
                  sitekey={FollozeState.envConfig.RECAPTCHA_SITE_KEY}
                  onChange={this.props.onCaptchaChange} />
              </div>
            }
          </EnterEmailForm>
        }
        {this.props.error && <LoginErrorMessage error={this.props.error} onLicenseRequest={this.props.onLicenseRequest}/>}
        <div className='marketing-logos'>
          <a target='_blank' href="https://www.g2.com/products/folloze/take_survey"><img src={marketingLoginG2} alt="G2 Reviews Logo"/></a>
          <a target='_blank' href="https://trustradi.us/folloze-login"><img src={marketingLoginTr} alt="TrustRadius Reviews Logo" /></a>
        </div>
      </div>

    )
  }
}

EnterEmail.propTypes = {
  form: object.isRequired,
  error: string.isRequired,
  onFormChange: func.isRequired,
  onFormSubmit: func.isRequired,
  onCaptchaChange: func,
  renderCaptcha: PropTypes.bool.isRequired,
  onLicenseRequest: func,
  isSending: PropTypes.bool
}

export default EnterEmail

import Validator from 'validatorjs'

const URL_VALIDATION_ERROR = 'Please provide a valid URL'

export const extractRouteFromUrl = () => {
  return location.pathname.split('/')[2]
}

export const getUrlParamValue = (url, param) => {
  const regexp = new RegExp(`${param}=([^&#]*)`)
  const matches = url.match(regexp)
  if (matches) {
    return matches[1]
  }
}

export const addUrlParamValue = (url, name, value) => {
  const operator = /\?/.test(url) ? '&' : '?'
  return `${url}${operator}${name}=${value}`
}

const getParamValue = (val) => {
  return (typeof val !== 'string') ? JSON.stringify(val) : val
}

export const paramsToQueryString = (params) => {
  return `?${Object.keys(params).map(key => key + '=' + getParamValue(params[key])).join('&')}`
}

export const isValidUrl = (url) => validateUrl(url)

export const validateUrl = (url) => {
  const urlRule = { url: 'url' }
  const urlError = { url: URL_VALIDATION_ERROR }
  const validator = new Validator({ url }, urlRule, urlError)

  return validator.passes()
}

export const removeQueryStringParam = (queryString, param) => {
  const searchString = queryString.replace('?', '&')
  const result = searchString.replace(new RegExp(`&${param}=[^&]*`), '')
  return result.replace('&', '?')
}

export const queryToParamsObject = (query) => {
  const result = {}

  const params = new URLSearchParams(query)
  const entries = params.entries()

  for (const [key, value] of entries) {
    result[key] = value
  }

  return result
}

export const withScheme = url => {
  return !/^(http|https)?:\/\//i.test(url) ? `https://${url}` : url
}

export const getCurrentQueryString = () => {
  return window.location.search
}

export const appendQueryParamsToUrl = (originalUrl, params) => {
  const url = new URL(originalUrl)
  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(key, value)
  }
  return url.toString()
}
